export const RECEIVE_POTENTIAL_PORTFOLIO_ITEMS = 'RECEIVE_POTENTIAL_PORTFOLIO_ITEMS';
export const RECEIVE_POTENTIAL_PORTFOLIO_ITEM = 'RECEIVE_POTENTIAL_PORTFOLIO_ITEM';
export const REMOVE_POTENTIAL_PORTFOLIO_ITEM = 'REMOVE_POTENTIAL_PORTFOLIO_ITEM';

export const receivePotentialPortfolioItems = (potentialPortfolioItems) => ({
  type: RECEIVE_POTENTIAL_PORTFOLIO_ITEMS,
  potentialPortfolioItems,
});

export const receivePotentialPortfolioItem = (potentialPortfolioItem) => ({
  type: RECEIVE_POTENTIAL_PORTFOLIO_ITEM,
  potentialPortfolioItem,
});

export const removePotentialPortfolioItem = (potentialPortfolioItem) => ({
  type: REMOVE_POTENTIAL_PORTFOLIO_ITEM,
  potentialPortfolioItem,
});
