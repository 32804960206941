import {map} from 'lodash';
import req from 'shared/req';
import {achievementItemForPotentialPortfolioItem} from './college-discovery';
import {
  receivePotentialPortfolioItem,
  receivePotentialPortfolioItems,
  removePotentialPortfolioItem,
} from './potential-portfolio-items';
import {receiveRecommendedCollegeIds} from './recommended-college-ids';

export const formatAchievement = (item) => ({
  scholarshipType: item.scholarshipType,
  portfolioItem: item.portfolioItem,
  description: item.description || item.name,
});

export const getAchievements = () => (dispatch) =>
  req({
    url: '/v1/users/me/potential-portfolio-items',
    method: 'get',
  }).then((items) => {
    const achievements = map(items, achievementItemForPotentialPortfolioItem);
    dispatch(receivePotentialPortfolioItems(achievements));

    return achievements;
  });

export const saveAchievement = (item) => (dispatch) =>
  req({
    url: '/v1/users/me/potential-portfolio-items',
    method: 'post',
    data: formatAchievement(item),
  }).then(({_id}) => dispatch(receivePotentialPortfolioItem({...item, _id})));

export const removeAchievement = (item) => (dispatch) =>
  req({
    url: `/v1/users/me/potential-portfolio-items/${item._id}`,
    method: 'delete',
  }).then(() => dispatch(removePotentialPortfolioItem(item)));

export const getRecommendedCollegeIds = () => (dispatch) =>
  req({
    url: '/v1/colleges/discover/high_school',
    method: 'get',
    data: {
      fields: '_id',
      limit: 40,
      'with-microscholarships': true,
    },
  }).then((colleges) => {
    dispatch(receiveRecommendedCollegeIds(colleges));
  });
