import {setDebugger} from 'src/utils/debuggers';

const vars = (
  typeof process.env.VARS === 'string' ? JSON.parse(process.env.VARS) : process.env.VARS || {}
) as {
  API_GATEWAY: string;
  EXPERIENCE_EVERYWHERE_DOMAIN: string;
  NOTIFICATIONS_URL: string;
  googleClientId: string;
  SEGMENT_KEY: string;
  ONESIGNAL_APP_ID: string;
  ORIGIN: string;
  isSPA: boolean;
  isProduction: boolean;
  isStaging: boolean;
  serviceName: string;
  version: string;
  otel: {
    OTEL_EXPORTER_ZIPKIN_ENDPOINT: string;
    OTEL_SERVICE_NAME: string;
    OTEL_TRACES_SAMPLER: string;
    OTEL_TRACES_SAMPLER_ARG: number;
  };
};

// Opentelemetry instrumentations read environment variables from the window
Object.assign(window, vars.otel);

setDebugger('vars', vars);
export default vars;
