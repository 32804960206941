import {mapValues, findIndex} from 'lodash';
import req from 'shared/req';
import {errorMessage} from 'shared/actions/notifications';

export const CHANGE_STEP = 'CHANGE_STEP';
export const RECEIVE_STUDENT = 'RECEIVE_STUDENT';

const FIELDS = [
  'firstName',
  'lastName',
  'postalCode',
  'addressLine1',
  'addressLine2',
  'city',
  'subdivision',
  'countyName',
  'countyFips',
  'loc',
  'birthdayDay',
  'birthdayMonth',
  'birthdayYear',
  'gender',
  'customGenderIdentity',
  'isUsCitizenOrResident',
  'howHeard',
  'signupFrl',
  'currentInstitution',
  'highSchoolInfo',
  'communityCollegeInfo',
].join(',');

export const receiveStudent = (student) => ({type: RECEIVE_STUDENT, student});

export const saveStudent = (student) => (dispatch) => {
  const url = `/v1/users/me?fields=${FIELDS}`;
  // Remove all empty strings that may have been set by redux-form's onBlur being called :(
  // See: https://github.com/erikras/redux-form/issues/440
  // Note: This only goes 1 level deep so objects like highSchoolInfo will be unaffected
  const cleanStudent = mapValues(student, (value) => (value === '' ? null : value));

  return new Promise((resolve, reject) =>
    req({
      url,
      method: 'PATCH',
      data: cleanStudent,
    })
      .then((modifiedStudent) => {
        dispatch(receiveStudent(modifiedStudent));
        resolve();
      })
      .catch((errors) => {
        const transferSemesterIdx = findIndex(errors, (err) => err.field === 'transfer_semester');

        if (transferSemesterIdx > -1) {
          const errs = {};
          errs.communityCollegeInfo = {transferSemester: errors[transferSemesterIdx].message};

          reject(errs);
        } else {
          dispatch(errorMessage());
          if (window.Raven) {
            window.Raven.captureMessage('ManualOnboardingError', {
              extra: {
                errors,
                student,
              },
            });
          }
          reject(errors);
        }
      })
  );
};
