import {ready, parseQueryString} from 'shared/utils';
import pageView from 'shared/page-view';

const logSignupInvitationVisit = () => {
  if (window.Data.signupInvitationVisitCode) {
    const params = parseQueryString(location.search.slice(1));

    pageView(location.pathname, 'join-event', {
      invite_code: window.Data.signupInvitationVisitCode,
      ...params,
    });
  }
};

ready(() => {
  logSignupInvitationVisit();
});
