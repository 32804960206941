import req from 'shared/req';

export const RECEIVE_STUDENT = 'RECEIVE_STUDENT';
export const CLEAR_STUDENT = 'CLEAR_STUDENT';

export const receiveStudent = (student) => ({
  type: RECEIVE_STUDENT,
  student,
});

export const clearStudent = () => ({
  type: CLEAR_STUDENT,
});

export const updateUser = (data, fields = '_id') => {
  const id = data.id || 'me';
  delete data.id;

  const url = `/v1/users/${id}?fields=${fields}`;

  return req({
    url,
    method: 'patch',
    data,
  });
};

export const getUser = (id = 'me', fields = '_id') => {
  const url = `/v1/users/${id}`;

  return req({
    url,
    method: 'get',
    data: {fields},
  });
};

export const getStudent =
  (id = 'me', fields = '_id') =>
  (dispatch) =>
    getUser(id, fields).then((student) => dispatch(receiveStudent(student)));

export const getStudentInformation =
  (id = 'me', fields) =>
  async (dispatch) => {
    if (Array.isArray(fields)) fields = fields.join(',');
    const userInfo = await getUser(id, fields);
    dispatch(receiveStudent(userInfo));
  };

export const evaluateEligibility = () =>
  req({
    url: '/v1/users/me/evaluate-eligibility',
    method: 'post',
  });
