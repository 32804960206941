import {Capacitor} from '@capacitor/core';

declare global {
  interface Window {
    analytics?: {
      track: (event: string, data: object) => void;
      page?: (pathname: string, properties?: object) => void;
    };
  }
}

export function track(event: string, data: object): void {
  if (!window.analytics) return;

  const mergedData = {
    platform: Capacitor.getPlatform(),
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    ...data,
  };

  window.analytics.track(event, mergedData);
}

export function trackPage(pathname: string): void {
  if (window.analytics && window.analytics.page) {
    window.analytics.page(pathname, {platform: Capacitor.getPlatform()});
  }
}

export const Track = {
  onboardingStepComplete: (userId: string, stepKey: string): void => {
    track('Onboarding Step Completed', {
      step: stepKey,
      user_id: userId,
    });
  },

  onboardingComplete: (userId: string, educationPhase: string): void => {
    const event =
      educationPhase === 'community-college'
        ? 'Transfer Registration Completed'
        : 'Registration Completed';

    track(event, {user_id: userId});
  },

  onCommunityCollegeOnboardingFourYear: (
    userId: string,
    transferring: boolean,
    currentInstitutionId: string | null
  ): void => {
    track('cc_onboarding_four_year', {
      _id: userId,
      transferring,
      unitid: currentInstitutionId,
      timestamp: new Date(),
    });
  },

  seniorSurveyClicked: (userId: string, nextStepsPhaseEvent: string): void => {
    track(`senior survey - clicked ${nextStepsPhaseEvent} survey`, {userId});
  },

  targetMeCampaignViewed: (
    studentId: string,
    campaignId?: number,
    source = 'sponsored-recommendations'
  ): void => {
    track('show_campaign_event', {studentId, source, campaignId});
  },
};
