import req from './req';
import {parseQueryString} from './utils';

const pageView = (page, type = null, params = {}) => {
  req({
    url: '/v1/page-view',
    method: 'post',
    data: {
      page,
      type,
      ...params,
    },
  });
};
export default pageView;

export const pageViewForLocation = (location) => {
  const params = [];
  const {search, pathname} = location;
  let queryParams = {};
  if (search && search.length) {
    queryParams = parseQueryString(search.substring(1));
  }
  let page = pathname.substr(1);

  switch (page) {
    case 'colleges':
      page = 'college-search';
      break;
    case 'students/rules':
      page = 'rules';
      break;
  }

  params.push(page);

  if (page.match(/edu\/(.*)/)) {
    params.push('college-listing');
    params.push(queryParams);
  }
  return pageView(...params);
};
