import {Address} from 'src/types/user';
import {forEach} from 'lodash';

export interface AddressVerification {
  delivery_line_1: string;
  last_line: string;
  components: {
    primary_number: string;
    street_name: string;
    street_predirection: string;
    street_postdirection: string;
    street_suffix: string;
    secondary_number: string;
    secondary_designator: string;
    city_name: string;
    state_abbreviation: string;
    zipcode: string;
    plus4_code: string;
  };
  metadata: {
    county_name: string;
    county_fips: string;
    latitude: number;
    longitude: number;
  };
}

export function createAddressString(form: Address): string {
  const addressLine = form.addressLine2
    ? `${form.addressLine1} ${form.addressLine2}`
    : form.addressLine1;

  return `${addressLine}, ${form.city} ${form.subdivision} ${form.postalCode}`;
}

function getAddressLineFromVerification(components: AddressVerification['components']): string {
  // TODO clean this up. Can be done in one line
  const addressComponents = [
    components.primary_number,
    components.street_predirection,
    components.street_name,
    components.street_postdirection,
    components.street_suffix,
  ];

  let addressLine = '';
  forEach(addressComponents, (component) => {
    component ? (addressLine += `${component} `) : '';
  });

  addressLine = addressLine.trim();
  return addressLine;
}

function checkAddressMatch(
  addressString: string,
  form: Address,
  suggestion: AddressVerification
): boolean {
  let pattern = addressString;
  if (form.postalCode.match(/[0-9]{5}/)) {
    pattern = pattern + '(-[0-9]{4})?';
  }

  const regex = new RegExp(pattern, 'i');
  const smartyStreetsSuggestedAddressString = `${suggestion.delivery_line_1}, ${suggestion.last_line}`;
  const addressesMatch = smartyStreetsSuggestedAddressString.match(regex);
  return Boolean(addressesMatch);
}

export function getSuggestedAddress(
  addressString: string,
  form: Address,
  response: AddressVerification[]
): Address | false {
  if (!response || response.length === 0) return false;
  const suggestion = response[0] as AddressVerification;
  const components = suggestion.components;
  const metadata = suggestion.metadata;
  const addressLine1 = getAddressLineFromVerification(components);
  const suggestedAddress: Address = {
    addressLine1,
    addressLine2: components.secondary_number
      ? `${components.secondary_designator} ${components.secondary_number}`
      : '',
    city: `${components.city_name}`,
    subdivision: components.state_abbreviation,
    postalCode: `${components.zipcode}-${components.plus4_code}`,
    // TODO should this come from the response?
    country: form.country,
    countyName: metadata.county_name,
    countyFips: metadata.county_fips,
    latitude: metadata.latitude,
    longitude: metadata.longitude,
    addressVerified: false,
    confirmedUnverifiedAddress: false,
  };

  if (checkAddressMatch(addressString, form, suggestion)) {
    suggestedAddress.addressVerified = true;
  }

  return suggestedAddress;
}
