export interface LocalStorageItem {
  value: () => string | null;
  set: (value: string) => string;
  pop: () => string | null;
  remove: () => void;
}

const PREFIX = 'raiseme';
export function localStorageItem(key: string): LocalStorageItem {
  key = `${PREFIX}:${key}`;
  return {
    value: (): string | null => window.localStorage.getItem(key),
    set: (value: string): string => {
      window.localStorage.setItem(key, value);
      return value;
    },
    remove: (): void => {
      window.localStorage.removeItem(key);
    },
    pop: (): string | null => {
      const value = window.localStorage.getItem(key);
      window.localStorage.removeItem(key);
      return value;
    },
  };
}

export const successfulLoginRedirectPath = localStorageItem('next-path');
