import req from 'shared/req';

export const RECEIVE_COLLEGES = 'RECEIVE_COLLEGES';
export const FOLLOW_COLLEGE = 'FOLLOW_COLLEGE';
export const UNFOLLOW_COLLEGE = 'UNFOLLOW_COLLEGE';

export function receiveColleges(colleges) {
  const formattedColleges = colleges.map((college) => ({
    id: college._id,
    name: college.name,
    city: college.city,
    state: college.state,
    logoUrl: college.logo,
    bannerUrl: college.banner,
    following: true,
  }));

  return {
    type: RECEIVE_COLLEGES,
    colleges: formattedColleges,
  };
}

export function getColleges(url) {
  return (dispatch) =>
    req({
      url,
      method: 'get',
    }).then((colleges) => {
      dispatch(receiveColleges(colleges));
    });
}

export function followCollege(id) {
  return {
    type: FOLLOW_COLLEGE,
    id,
  };
}

export function unfollowCollege(id) {
  return {
    type: UNFOLLOW_COLLEGE,
    id,
  };
}
