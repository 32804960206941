// libaries
import $ from 'jquery';
import {ready} from 'shared/utils';
import {track} from 'src/utils/analytics';

ready(() => {
  document.cookie = 'screen_width = ' + window.screen.width;
  document.cookie = 'screen_height = ' + window.screen.height;

  $(document).on('click', '[data-segment-track="button"]', function () {
    const $this = $(this);
    const buttonName = $this.data('buttonName');

    if (buttonName) {
      track('Button Clicked', {
        buttonName: buttonName,
      });
    }
  });

  $('body').on('click', '[data-segment-track="link"]', function () {
    const $this = $(this);
    const linkName = $this.data('linkName');

    if (linkName) {
      track('Link Clicked', {
        linkName: linkName,
      });
    }
  });
});
