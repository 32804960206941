const titlesList = [
  {value: 'Mrs', ariaLabel: 'Missis'},
  {value: 'Mr', ariaLabel: 'Mister'},
  {value: 'Ms', ariaLabel: 'Mizz'},
  {value: 'Miss', ariaLabel: 'Miss'},
  {value: 'Dr', ariaLabel: 'Doctor'},
  {value: 'Rev', ariaLabel: 'Reverend'},
  {value: 'Mx', ariaLabel: 'Miks'},
];

export const NAME_TITLES = titlesList.map((title) => ({
  _id: title.value,
  value: title.value,
  text: title.value,
  'aria-label': title.ariaLabel,
}));

export const achievementItemsData = [
  {
    name: 'Band',
    emoji: '\u{1F941}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 1,
  },
  {
    name: 'Key Club',
    emoji: '\u{1F511}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 2,
  },
  {
    name: 'Student Government',
    emoji: '\u{1F3DB}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 3,
  },
  {
    name: 'Yearbook',
    emoji: '\u{1F4D9}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 4,
  },
  {
    name: 'Dance',
    emoji: '\u{1F483}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 5,
  },
  {
    name: 'National Honor Society',
    emoji: '\u{1F3C5}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 6,
    grades: [11, 12],
  },
  {
    name: 'Choir',
    emoji: '\u{1F3B6}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 7,
  },
  {
    name: 'Orchestra',
    emoji: '\u{1F3BB}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 8,
  },
  {
    name: 'FBLA',
    emoji: '\u{1F4BC}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 8,
  },
  {
    name: 'Debate Team',
    emoji: '\u{2696}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 9,
  },
  {
    name: 'Future Farmers of America',
    emoji: '\u{1F69C}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 10,
  },
  {
    name: 'Model UN',
    emoji: '\u{1F30E}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 11,
  },
  {
    name: 'Student Body President',
    emoji: '\u{1F5F3}',
    scholarshipType: 'LeadershipScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 11,
    grades: [11, 12],
  },
  {
    name: 'JROTC',
    emoji: '\u{1F396}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 12,
  },
  {
    name: 'President of a Club',
    emoji: '\u{1F4DD}',
    scholarshipType: 'LeadershipScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 12,
  },
  {
    name: 'Robotics',
    emoji: '\u{1F916}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 13,
  },
  {
    name: 'Student Body Treasurer',
    emoji: '\u{1F4B0}',
    scholarshipType: 'LeadershipScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 13,
  },
  {
    name: 'Color Guard',
    emoji: '\u{1F6A9}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 14,
  },
  {
    name: 'Newspaper',
    emoji: '\u{1F4F0}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 15,
  },
  {
    name: 'Boy / Girl Scouts',
    emoji: '\u{26FA}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 16,
  },
  {
    name: 'Drama Club',
    emoji: '\u{1F39F}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 17,
  },
  {
    name: 'Mock Trial',
    emoji: '\u{1F469}\u{200D}\u{2696}\u{FE0F}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 18,
  },
  {
    name: 'Science Olympiad',
    emoji: '\u{1F468}\u{1F3FD}\u{200D}\u{1F52C}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 19,
  },
  {
    name: 'Chess Club',
    emoji: '\u{1F451}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 20,
  },
  {
    name: '4-H Club',
    emoji: '\u{1F340}',
    scholarshipType: 'ExtracurricularActivityScholarship',
    portfolioItem: 'extracurricular_activity',
    rank: 21,
  },
  {
    name: 'English Class',
    emoji: '\u{1F4D6}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 1,
    description: 'English',
    portfolioItem: 'graded_course',
  },
  {
    name: 'Science Class',
    emoji: '\u{1F52C}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 2,
    description: 'Science',
    portfolioItem: 'graded_course',
  },
  {
    name: 'Math Class',
    emoji: '\u{1F4D0}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 3,
    description: 'Math',
    portfolioItem: 'graded_course',
  },
  {
    name: 'Social Studies Class',
    emoji: '\u{1F5FA}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 4,
    description: 'Social Studies',
    portfolioItem: 'graded_course',
  },
  {
    name: 'Spanish Class',
    emoji: '\u{1F1F2}\u{1F1FD}',
    scholarshipType: 'ForeignLanguageScholarship',
    portfolioItem: 'graded_course',
    description: 'Foreign Language',
    rank: 5,
  },
  {
    name: 'Honors Class',
    emoji: '\u{2B50}',
    scholarshipType: 'ChallengingClassScholarship',
    portfolioItem: 'graded_course',
    rank: 6,
    description: 'Honors',
  },
  {
    name: 'Art Class',
    emoji: '\u{1F3A8}',
    scholarshipType: 'CourseGradeScholarship',
    portfolioItem: 'graded_course',
    description: 'Fine Arts',
    rank: 7,
  },
  {
    name: 'French Class',
    emoji: '\u{1F1EB}\u{1F1F7}',
    scholarshipType: 'ForeignLanguageScholarship',
    portfolioItem: 'graded_course',
    description: 'Foreign Language',
    rank: 8,
  },
  {
    name: 'Physical Education',
    emoji: '\u{26F9}\u{1F3FB}\u{200D}\u{2640}\u{FE0F}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 9,
    description: 'Physical Education',
    portfolioItem: 'graded_course',
  },
  {
    name: 'AP Class',
    emoji: '\u{270D}\u{FE0F}',
    scholarshipType: 'ChallengingClassScholarship',
    rank: 10,
    grades: [11, 12],
    description: 'AP',
    portfolioItem: 'graded_course',
  },
  {
    name: 'Health Class',
    emoji: '\u{1F957}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 11,
    description: 'Physical Education',
    portfolioItem: 'graded_course',
  },
  {
    name: 'IB Class',
    emoji: '\u{1F310}',
    scholarshipType: 'ChallengingClassScholarship',
    rank: 12,
    grades: [11, 12],
    description: 'IB',
    portfolioItem: 'graded_course',
  },
  {
    name: 'Psychology',
    emoji: '\u{1F9E0}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 13,
    description: 'Non Core',
    portfolioItem: 'graded_course',
  },
  {
    name: 'Financial Literacy',
    emoji: '\u{1F4B3}',
    scholarshipType: 'CertificationScholarship',
    portfolioItem: 'graded_course',
    description: 'Financial Literacy',
    rank: 14,
  },
  {
    name: 'Drama Class',
    emoji: '\u{1F3AD}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 15,
    description: 'Fine Arts',
    portfolioItem: 'graded_course',
  },
  {
    name: 'Music Class',
    emoji: '\u{1F3BC}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 16,
    description: 'Fine Arts',
    portfolioItem: 'graded_course',
  },
  {
    name: 'Chinese Class',
    emoji: '\u{1F1E8}\u{1F1F3}',
    scholarshipType: 'ForeignLanguageScholarship',
    portfolioItem: 'graded_course',
    description: 'Foreign Language',
    rank: 17,
  },
  {
    name: 'German Class',
    emoji: '\u{1F1E9}\u{1F1EA}',
    scholarshipType: 'ForeignLanguageScholarship',
    portfolioItem: 'graded_course',
    description: 'Foreign Language',
    rank: 18,
  },
  {
    name: 'Civics',
    emoji: '\u{1F1FA}\u{1F1F8}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 19,
    description: 'Social Studies',
    portfolioItem: 'graded_course',
  },
  {
    name: 'Religion Class',
    emoji: '\u{26EA}',
    scholarshipType: 'CourseGradeScholarship',
    rank: 20,
    description: 'Non Core',
    portfolioItem: 'graded_course',
  },
  {
    name: 'College Campus Visit',
    emoji: '\u{1F3EB}',
    scholarshipType: 'CampusVisitScholarship',
    rank: 1,
    grades: [10, 11, 12],
    portfolioItem: 'college_interaction',
    description: 'Campus Visit',
  },
  {
    name: 'Community Service',
    emoji: '\u{1F49C}',
    scholarshipType: 'CommunityServiceScholarship',
    rank: 2,
    portfolioItem: 'community_service_event',
  },
  {
    name: 'College Fair',
    emoji: '\u{1F4C5}',
    scholarshipType: 'CollegeFairScholarship',
    rank: 3,
    portfolioItem: 'college_interaction',
  },
  {
    name: 'Job',
    emoji: '\u{1F4B5}',
    scholarshipType: 'WorkExperienceScholarship',
    portfolioItem: 'work_experience',
    rank: 4,
  },
  {
    name: 'Submitted the FAFSA',
    emoji: '\u{1F4EB}',
    scholarshipType: 'FafsaScholarship',
    rank: 5,
    grades: [12],
  },
  {
    name: 'Summer Program',
    emoji: '\u{2600}',
    scholarshipType: 'SummerProgramScholarship',
    rank: 6,
    portfolioItem: 'college_interaction',
  },
  {
    name: 'Babysit',
    emoji: '\u{1F37C}',
    scholarshipType: 'WorkExperienceScholarship',
    rank: 7,
    portfolioItem: 'work_experience',
  },
  {
    name: 'Soccer',
    emoji: '\u{26BD}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 1,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Basketball',
    emoji: '\u{1F3C0}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 2,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Football',
    emoji: '\u{1F3C8}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 3,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Volleyball',
    emoji: '\u{1F3D0}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 4,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Cross Country',
    emoji: '\u{1F3C3}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 5,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Track & Field',
    emoji: '\u{1F3C3}\u{1F3FD}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 6,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Softball',
    emoji: '\u{26BE}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 7,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Cheerleading',
    emoji: '\u{1F4E3}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 8,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Tennis',
    emoji: '\u{1F3BE}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 9,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Baseball',
    emoji: '\u{26BE}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 10,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Captain of a Team',
    emoji: '\u{1F3C6}',
    scholarshipType: 'LeadershipScholarship',
    portfolioItem: 'sports_activity',
    rank: 10,
  },
  {
    name: 'Swimming',
    emoji: '\u{1F3CA}\u{1F3FD}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 11,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Lacrosse',
    emoji: '\u{1F945}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 12,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Golf',
    emoji: '\u{26F3}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 13,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Wrestling',
    emoji: '\u{1F93C}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 14,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Field Hockey',
    emoji: '\u{1F3D1}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 15,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Gymnastics',
    emoji: '\u{1F938}\u{1F3FF}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 16,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Hockey',
    emoji: '\u{1F3D2}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 17,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Bowling',
    emoji: '\u{1F3B3}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 18,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'Water Polo',
    emoji: '\u{1F93D}\u{1F3FF}',
    scholarshipType: 'SportsActivityScholarship',
    rank: 19,
    portfolioItem: 'sports_activity',
  },
  {
    name: 'PSAT',
    emoji: '\u{270f}\u{fe0f}',
    scholarshipType: 'PsatPreActScholarship',
    rank: 1,
    grades: [10, 11, 12],
    description: 'PsatScore',
    portfolioItem: 'test_score',
  },
  {
    name: 'PreACT',
    emoji: '\u{270f}\u{fe0f}',
    scholarshipType: 'PsatPreActScholarship',
    rank: 1,
    grades: [10, 11, 12],
    description: 'PreActScore',
    portfolioItem: 'test_score',
  },
  {
    name: 'SAT',
    emoji: '\u{23F1}',
    scholarshipType: 'SatActScholarship',
    rank: 2,
    grades: [11, 12],
    portfolioItem: 'test_score',
    description: 'SatScore',
  },
  {
    name: 'ACT',
    emoji: '\u{23F1}',
    scholarshipType: 'SatActScholarship',
    rank: 2,
    grades: [11, 12],
    portfolioItem: 'test_score',
    description: 'ActScore',
  },
  {
    name: 'AP Exam',
    emoji: '\u{270f}\u{fe0f}',
    scholarshipType: 'ApScoreScholarship',
    rank: 3,
    grades: [11, 12],
    portfolioItem: 'test_score',
    description: 'ApScore',
  },
];
