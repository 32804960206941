import * as yup from 'yup';
import {pluralize} from 'shared/utils';

export type ApiError =
  | {
      field?: string;
      type: string;
      message: string;
    }
  | {
      type?: string;
      field: string;
      message: string;
    };

export interface ApiErrorHandlerType<FieldErrors = Record<string, string>> {
  new (apiErrors: ApiError[]): ApiErrorHandler<FieldErrors>;

  validateApiErrors: (reqErrorContent: unknown) => ApiError[] | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ensureArray = (obj: unknown): any[] => (Array.isArray(obj) ? obj : []);

export abstract class ApiErrorHandler<FieldErrors = Record<string, string>> extends Error {
  apiErrors: ApiError[] = [];
  reqErrorContent: unknown = null;

  static readonly errorSchema = yup
    .array()
    .min(1)
    .of(
      yup.mixed().test('Field error validation', 'Field error validation error', (object) => {
        if ('type' in object) {
          return yup
            .object<ApiError>({
              message: yup.string().required(),
              type: yup.string().required(),
              field: yup.string().notRequired(),
            })
            .isValidSync(object, {strict: true});
        }

        return yup
          .object<ApiError>({
            message: yup.string().required(),
            field: yup.string().required(),
            type: yup.string().notRequired(),
          })
          .isValidSync(object, {strict: true});
      })
    );

  constructor(reqErrorContent: unknown) {
    super(
      `Received ${ensureArray(reqErrorContent).length} field ${pluralize(
        ensureArray(reqErrorContent).length,
        'error'
      )}`
    );
    this.apiErrors = ApiErrorHandler.validateApiErrors(reqErrorContent) || [];
    this.reqErrorContent = reqErrorContent;
  }

  static validateApiErrors(reqErrorContent: unknown): ApiError[] | null {
    try {
      return ApiErrorHandler.errorSchema.validateSync(reqErrorContent, {strict: true});
    } catch (_) {
      console.warn('Invalid api error schema', reqErrorContent);
      return null;
    }
  }

  abstract getFieldErrors(): FieldErrors;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getMappedFieldErrors(fieldMap: Record<string, string>): FieldErrors {
    return this.getFieldErrors();
  }

  logUnknownApiError(apiError: ApiError): void {
    console.error(`Unimplemented type=${apiError.type} class=${this.name}`);
  }

  hasErrors(): boolean {
    return this.apiErrors.length > 0;
  }
}
