/**
 *  All request traffic is sent to API_GATEWAY by default except routes matched in this function.
 *  This allows cookies to be set on the current origin
 * @param pathname {string}
 * @returns {boolean}
 */
const shouldSendToCurrentOrigin = (pathname) => {
  const tests = [
    /\/logout/,
    /\/v1\/basic\/(login|signup|password|switch-account|token|validate-session)/,
    /\/v1\/google\/(login|signup)/,
    /\/students\/claim_account/,
    /\/v1\/claim-accounts/,
    /\/v1\/experience-everywhere/,
    new RegExp('/v1/users/me/password'),
  ];

  return tests.some((regex) => regex.test(pathname));
};

module.exports = shouldSendToCurrentOrigin;
