import $ from 'jquery';
import {ready} from 'shared/utils';

window.addNewTabTooltip = function () {
  $('[data-toggle="new-tab-tooltip"]')
    .attr('aria-label', function (i, origValue) {
      // attach the phrase " (Opens in new tab)" there is an aria-label but it doesn't have an
      // "(Opens …)" in it. If you want to override, just put a phrase like "(Opens e-mail client)"
      if (!origValue) return origValue;
      if (origValue.match(/\(Opens.*\)/)) {
        return origValue;
      }
      return origValue + ' (Opens in new tab)';
    })
    .tooltip({
      template:
        '<div class="opens-new-tab-tooltip tooltip shared-tooltip bottom one-line-tooltip" aria-hidden="true">' +
        '<div class="tooltip-arrow"></div>' +
        '<div class="tooltip-inner">' +
        '</div>' +
        '</div>',
      placement: 'bottom',
      title: 'Opens in new tab',
      trigger: 'hover focus',
    });
};

ready(() => {
  window.addNewTabTooltip();
});
