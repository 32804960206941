/* eslint-disable @typescript-eslint/no-explicit-any */
import {isEmpty, omit, omitBy, shuffle} from 'lodash';
import qs from 'qs';
import {achievementsCount} from 'shared/registration/actions/achievement-items';
import {formatAchievement} from 'shared/registration/actions/achievement-selection';
import {achievementItemForPotentialPortfolioItem} from 'shared/registration/actions/college-discovery';
import req from 'shared/req';
import {USER_FIELDS_TO_OMIT} from 'shared/student/registration/actions';
import {AchievementOption, InstitutionSuggestion} from 'src/types/education';
import {CitizenshipOptions, EducationPhase} from 'src/types/enums';
import JWTService from './JWTService';

export interface VerificationCodeResult {
  valid: boolean;
  error: string;
}

export interface ClaimAccountResponse {
  identifier: string;
  firstName: string;
  id: string;
  alternateAccountsCount: number;
  educationPhase: EducationPhase;
  requester: {
    name: string;
    lastName?: string;
    logoUrl?: string;
  };
}

interface SavePotentialAchievementResponse {
  _id: string;
}

interface SendPhoneVerificationResponse {
  success: boolean;
}

interface CheckPhoneVerificationCodeResponse {
  success: boolean;
  errors: {message: string};
}

const formatUserBeforeSend = (values: any): any => {
  values = omit(values, USER_FIELDS_TO_OMIT);
  values = omitBy(values, (_, key) => key.startsWith('_'));
  if (values.birthday && values.birthday instanceof Date) {
    values.birthday = values.birthday.toISOString();
  }

  if (values.currentInstitution && isEmpty(values.currentInstitution.parentId)) {
    values.currentInstitution = null;
  }

  if (values.isUsCitizenOrResident === CitizenshipOptions.DECLINE) {
    values.isUsCitizenOrResident = null;
  } else if (values.isUsCitizenOrResident === CitizenshipOptions.YES) {
    values.isUsCitizenOrResident = true;
  } else if (values.isUsCitizenOrResident === CitizenshipOptions.NO) {
    values.isUsCitizenOrResident = false;
  }

  return values;
};

const formatUser = (values: any): any => {
  if (values.isUsCitizenOrResident === true) {
    values.isUsCitizenOrResident = CitizenshipOptions.YES;
  } else if (values.isUsCitizenOrResident === false) {
    values.isUsCitizenOrResident = CitizenshipOptions.NO;
  }

  return values;
};

export default class UserService {
  static async getData<T>(fields: string[]): Promise<T> {
    const user = await req({
      url: `/v1/users/me?${qs.stringify({fields: fields.join(',')})}`,
    });

    return formatUser(user) as T;
  }

  static async update(data: Record<string, any>, fields?: string[]): Promise<any> {
    const formattedData = formatUserBeforeSend(data);
    const requestedFields = Array.isArray(fields) ? fields.join(',') : '_id';
    const response = await req({
      url: `/v1/users/me?${qs.stringify({fields: requestedFields})}`,
      method: 'PATCH',
      data: formattedData,
    });

    return response;
  }

  static async destroy(id: string, reason: string): Promise<any> {
    const response = req({
      url: `/v1/users/${id}`,
      data: {reason},
      method: 'delete',
    });

    JWTService.userToken.remove();

    return response;
  }

  static async getAchievementItems(): Promise<AchievementOption[]> {
    const response = await req<AchievementOption[]>({
      url: `/v1/users/me/achievement-items?${qs.stringify({count: achievementsCount()})}`,
    });

    const achievements = shuffle(response);
    return achievements;
  }

  static async getPotentialPortfolioItems(): Promise<AchievementOption[]> {
    const response = await req<AchievementOption[]>({
      url: `/v1/users/me/potential-portfolio-items?${qs.stringify({count: achievementsCount()})}`,
    });

    return shuffle(response).map(
      achievementItemForPotentialPortfolioItem as (x: any) => AchievementOption
    );
  }

  static async savePotentialAchievement(
    achievement: AchievementOption
  ): Promise<SavePotentialAchievementResponse> {
    const formattedData = formatAchievement(achievement);
    const response = await req<SavePotentialAchievementResponse>({
      url: '/v1/users/me/potential-portfolio-items',
      method: 'POST',
      data: formattedData,
    });

    return response;
  }

  static async removePotentialAchievement(id: string): Promise<void> {
    await req({
      url: `/v1/users/me/potential-portfolio-items/${id}`,
      method: 'DELETE',
    });
  }

  static async getSuggestedColleges(
    scholarshipTypes: string[],
    limit = 10
  ): Promise<InstitutionSuggestion[]> {
    const query = qs.stringify({
      scholarshipTypes: scholarshipTypes.join(','),
      limit: limit,
    });
    let response;

    try {
      response = await req({
        url: `/v1/micro-scholarships/by-college?${query}`,
      });
    } catch (error) {
      console.error(`Error getting suggested colleges ${error}`);
      return [];
    }

    return response as InstitutionSuggestion[];
  }

  static async sendPhoneVerification(
    mobile: string,
    method: 'sms' | 'call' = 'sms'
  ): Promise<boolean> {
    const response = await req<SendPhoneVerificationResponse>({
      url: '/v1/verify-phone-number',
      method: 'POST',
      data: {
        phone_number: mobile,
        via_method: method,
      },
    });

    if (response.success !== true) {
      console.error(`Failed to send verification to ${mobile}`);
      return false;
    }

    return true;
  }

  static async checkPhoneVerificationCode(
    mobile: string,
    code: string
  ): Promise<VerificationCodeResult> {
    const response = await req<CheckPhoneVerificationCodeResponse>({
      url: '/v1/verify-phone-number/check-code',
      method: 'POST',
      data: {
        code,
        phone_number: mobile,
      },
    });

    if (response.success !== true) {
      console.error(`Failed to send verification to ${mobile}`);
      return {
        valid: response.success,
        error: response.errors.message,
      };
    }

    return {
      error: '',
      valid: true,
    };
  }

  static async getClaimAccountData(code: string): Promise<ClaimAccountResponse> {
    const user = await req<ClaimAccountResponse>({
      url: `/v1/claim-accounts?${qs.stringify({c: code})}`,
    });

    return user;
  }

  static async confirmTermsOfUse(): Promise<void> {
    await req({
      url: '/v1/users/me/confirm-terms-of-use',
      method: 'PATCH',
    });
  }
}
