import 'bootstrap';
import $ from 'jquery';
import 'shared/cookie-preference';
import 'shared/join-page-logger';
import 'shared/snippets/analytics';
import 'shared/snippets/new-tab-tooltip';
import 'shared/snippets/public-navbar';
import {ready} from 'shared/utils';
import 'slick-carousel';
import {track} from 'src/utils/analytics';

ready(() => {
  // https://segment.com/docs/integrations/google-analytics/#client-side-library-methods
  window.analytics &&
    window.analytics.ready(function () {
      $.scrollDepth();
    });

  // 8/24/18: REMOVE THIS WHEN NEW NAVBAR IS INNNN
  if ($('.navbar-inverse').length > 0) {
    $(document).scroll(function () {
      const inverted = $(document).scrollTop() < $('#hero').offset().top + $('#hero').height();
      $('.navbar').toggleClass('navbar-inverse', inverted);
      $('.navbar').toggleClass('navbar-default', !inverted);
      $('body').css(
        'background-color',
        inverted ? $('.navbar').css('background-color') : 'transparent'
      );
    });
  }

  const $testimonialSlider = $('.testimonial-slider');
  const TEXTS = [
    'Wynn Phillips testimonial',
    'Shay Mousavi testimonial',
    'Jesus Guadalupe Valenzuela testimonial',
  ];

  $testimonialSlider
    .on('init breakpoint', function () {
      $testimonialSlider.find('li > button').each(function (i, el) {
        $(el).text(TEXTS[i]);
      });
    })
    .slick({
      dots: false,
      arrows: false,
      infinite: true,
      accessibility: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      touchThreshold: 6,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    })
    .on('afterChange', function () {
      track('Testimonials Interaction');
    });

  const $hiwTestimonialSlider = $('.hiw-testimonial-slider');
  const HIW_TEXTS = [
    'Wynn Phillips testimonial',
    'Shay Mousavi testimonial',
    'Jesus Guadalupe Valenzuela testimonial',
  ];

  $hiwTestimonialSlider
    .on('init breakpoint', function () {
      $hiwTestimonialSlider.find('li > button').each(function (i, el) {
        $(el).text(HIW_TEXTS[i]);
      });
    })
    .slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      touchThreshold: 6,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: false,
          },
        },
      ],
    })
    .on('afterChange', function () {
      track('Testimonials Interaction');
    });

  const $collegePartnersSlider = $('#college-overview-partners-slider');
  $collegePartnersSlider.slick({
    accessibility: true,
    arrows: true,
    dots: true,
  });

  const $resultsSlider = $('#results-slider');
  $resultsSlider.slick({
    accessibility: true,
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
  });
});
