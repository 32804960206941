import {SpanAttributes} from '@opentelemetry/api/build/src/trace/attributes';
import {Capacitor} from '@capacitor/core';
import {SemanticAttributes} from '@opentelemetry/semantic-conventions';

const traceAttributes: SpanAttributes = {
  platform: Capacitor.getPlatform(),
  [SemanticAttributes.HTTP_USER_AGENT]: window.navigator.userAgent,
  [SemanticAttributes.HTTP_TARGET]: window.location.pathname,
};

export const setTraceAttributes = (
  attributes: Partial<{
    id: string;
    userIdentifier: string;
  }>
): void => {
  Object.assign(traceAttributes, {
    [SemanticAttributes.ENDUSER_ID]: attributes.id,
    [SemanticAttributes.ENDUSER_ROLE]: attributes.userIdentifier,
  });
};

export const getTraceAttributes = (): SpanAttributes => traceAttributes;
