import {camelCase, difference} from 'lodash';
import req from 'shared/req';

// used to memoize requests so that the same request is not made multiple times
const memo = {};

export type FeatureFlags = Record<string, boolean>;

export default class FeatureFlagService {
  // used to keep track of feature flags we have already fetched so we don't have to fetch the status
  // multiple times
  static featureFlagStatuses = {};

  static async getFeatureFlagStatuses<Flags = FeatureFlags>(names: string[]): Promise<Flags> {
    const url = '/v1/feature-flags';
    let response = {};

    const flagsToFetch = difference(names, Object.keys(FeatureFlagService.featureFlagStatuses));

    const featureFlagNames = flagsToFetch.join(',');

    try {
      if (flagsToFetch.length) {
        if (!memo[featureFlagNames]) {
          memo[featureFlagNames] = req({url, method: 'GET', data: {names: featureFlagNames}});
        }

        response = await memo[featureFlagNames];
      }
    } catch (error) {
      console.error(`Error getting status for feature flags: ${featureFlagNames}`, error);
    }

    Object.keys(response).forEach((key) => {
      FeatureFlagService.featureFlagStatuses[key] = response[key];
    });

    const statuses = {};

    names.map(camelCase).forEach((name) => {
      statuses[name] = FeatureFlagService.featureFlagStatuses[name];
    });

    return statuses as Flags;
  }

  static async getFeatureFlagStatus(name: string): Promise<boolean> {
    return this.getFeatureFlagStatuses([name]).then((flags) => flags[name]);
  }

  // for testing purposes, give a way to reset the memoization
  static clearMemoization(): void {
    Object.keys(memo).forEach((key) => delete memo[key]);
    Object.keys(FeatureFlagService.featureFlagStatuses).forEach(
      (key) => delete FeatureFlagService.featureFlagStatuses[key]
    );
  }
}
