// library
import req from 'shared/req';
import {filter, find, map, uniq} from 'lodash';
import {evaluateEligibility} from 'shared/actions/student';
import {receiveStudent} from './student';
import {RECEIVE_COLLEGES} from './schools';
import {achievementItemsData} from '../constants';

export const fetchColleges =
  (limit = 10) =>
  (dispatch, getState) => {
    const state = getState();
    const potentialPortfolioItems = state.potentialPortfolioItems;
    const collegeIds = state.recommendedCollegeIds.join(',');
    const scholarshipTypes = uniq(
      map(
        potentialPortfolioItems,
        (potentialPortfolioItem) => potentialPortfolioItem.scholarshipType
      )
    ).join(',');

    return req({
      url: '/v1/micro-scholarships/by-college',
      method: 'get',
      data: {
        scholarshipTypes,
        limit,
        collegeIds,
      },
    }).then((colleges) => dispatch({type: RECEIVE_COLLEGES, colleges}));
  };

export const evaluateStudentEligibility = () => (dispatch, getState) => {
  const {student} = getState();

  if (student && student.eligibilityEvaluatedAt) {
    return;
  }

  evaluateEligibility().then(() => {
    dispatch(receiveStudent({eligibilityEvaluatedAt: Date.now()}));
  });
};

export const achievementItemForPotentialPortfolioItem = (potentialPortfolioItem) => {
  const achievement = find(achievementItemsData, (achievementItem) => {
    const scholarshipTypesMatch =
      achievementItem.scholarshipType === potentialPortfolioItem.scholarshipType;

    const portfolioItemsMatch =
      achievementItem.portfolioItem === potentialPortfolioItem.portfolioItem;

    const descriptionMatches =
      achievementItem.description &&
      achievementItem.description === potentialPortfolioItem.description;
    const descriptionMatchesName = achievementItem.name === potentialPortfolioItem.description;
    const descriptionsMatch = descriptionMatches || descriptionMatchesName;

    return scholarshipTypesMatch && portfolioItemsMatch && descriptionsMatch;
  });

  return {...achievement, _id: potentialPortfolioItem._id};
};

export const followColleges = (onSubmit) => (dispatch, getState) => {
  const {
    colleges: {colleges},
  } = getState();

  const followed = filter(colleges, 'following');

  return onSubmit({
    followedCollegeIds: map(followed, 'id'),
  });
};
