import req from '../req';
import {loadAnalytics, ready} from '../utils';
import $ from 'jquery';

/**
 * Detect bot browsers
 * @param {string} User Agent of the browser
 * @return {bool} Is the current user a bot or not
 */
export const isBot = (userAgent) =>
  window._DATADOG_SYNTHETICS_BROWSER !== undefined || // Datadog synthetics
  /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(userAgent); // Crawler bots

export const shouldLoadAnalytics = async (currentPreferences) => {
  // Prevent noise during development. Change if testing
  if (process.env.NODE_ENV === 'development') return false;

  if (isBot(navigator.userAgent)) {
    // Prevent noise from bots in analytics
    return false;
  }

  const {inEU} = await req({url: '/v1/ip-info'});
  if (currentPreferences && 'performance' in currentPreferences) {
    return !!currentPreferences.performance;
  } else {
    return !inEU; // Turn off analytics for EU region
  }
};

export const loadAnalyticsIfAllowed = async (currentPreferences) => {
  if (await shouldLoadAnalytics(currentPreferences)) {
    loadAnalytics();
  }
};

export const showCookiePrefsFooter = () => {
  ready(() => {
    $('.cookie-preference-footer').removeClass('hidden');
  });
};

export const hideCookiePrefsFooter = () => {
  ready(() => {
    $('.cookie-preference-footer').addClass('hidden');
  });
};
