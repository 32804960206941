import $ from 'jquery';
import {ready} from 'shared/utils';

ready(() => {
  // Toggle classes for logged out public navbar
  function swapNavbarStyles() {
    // Text on banners begins displaying 100px down, navbar is 80
    const transparent = $(document).scrollTop() < 40;
    $('.public-navbar').toggleClass('navbar-default', !transparent);
    $('.public-navbar').toggleClass('navbar-transparent', transparent);
    if (transparent) {
      $('.public-navbar-login-button').removeClass('primary-outline').addClass('alternate-outline');
      $('.public-navbar-signup-button').removeClass('primary').addClass('alternate');
    } else {
      $('.public-navbar-login-button').removeClass('alternate-outline').addClass('primary-outline');
      $('.public-navbar-signup-button').removeClass('alternate').addClass('primary');
    }
  }

  if ($('.navbar-transparent').length > 0) {
    $(document).scroll(swapNavbarStyles);
  }

  // Toggle styles for mobile public navbar
  $('.public-navbar-mobile-menu-button').on('click', function () {
    $('.public-navbar-mobile-menu-button').toggleClass('open-menu');
    $('.public-navbar-overlay').toggleClass('shared-overlay');
    $('.public-navbar-mobile-menu').toggleClass('show-mobile-menu');
  });

  $('.public-navbar-menu-item').on('click', function (e) {
    $(e.target).closest('.public-navbar-menu-item').toggleClass('show-dropdown-submenu');
  });

  // dropdown menu styling
  $('.public-navbar-menu-item').on('focusin', function () {
    $(this).addClass('focus-within');
  });

  $('.public-navbar-menu-item').on('focusout', function () {
    $(this).removeClass('focus-within');
  });
});
