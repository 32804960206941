import {shuffle} from 'lodash';
import req from 'shared/req';

export const RECEIVE_ACHIEVEMENT_ITEMS = 'RECEIVE_ACHIEVEMENT_ITEMS';

const receiveAchievementItems = (items) => ({
  type: RECEIVE_ACHIEVEMENT_ITEMS,
  achievementItems: items,
});

export const achievementsCount = () => 20;

export const fetchAchievementItems = () => (dispatch) => {
  req({
    url: '/v1/users/me/achievement-items',
    method: 'get',
    data: {count: achievementsCount()},
  }).then((achievements) => {
    dispatch(receiveAchievementItems(shuffle(achievements)));
  });
};
