import req from 'shared/req';
import {localStorageItem} from 'src/utils/storage';
import {get} from 'lodash';

export default class JWTService {
  static userToken = localStorageItem('token');
  static async token(): Promise<string> {
    if (JWTService.userToken.value()) {
      return JWTService.userToken.value() as string;
    }

    const tokenResponse = (await req({url: '/v1/basic/token'})) as {token: string};
    return JWTService.userToken.set(tokenResponse.token);
  }

  static updateTokenFromResponse(response: unknown): void {
    const token = get(response, 'meta.token', false) || get(response, 'token', false);

    if (token) {
      JWTService.userToken.set(token);
    }
  }
}
