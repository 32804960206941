const xs = 480;
const sm = 768;
const md = 992;
const lg = 1200;

export const rawMediaQueries = {
  MS_AND_DOWN_MAX: `(max-width: ${sm - 1}px)`,
};

export interface MQ {
  XS: boolean;
  SM: boolean;
  MS: boolean;
  MD: boolean;
  LG: boolean;
  MS_AND_DOWN: boolean;
  SM_AND_DOWN: boolean;
  MD_AND_DOWN: boolean;
  MD_AND_UP: boolean;
  SM_AND_UP: boolean;
  MS_AND_UP: boolean;
}

const queries = [
  {key: 'XS', query: `(max-width: ${xs - 1}px)`},
  {key: 'MS', query: `(min-width: ${xs}px) and (max-width: ${sm - 1}px)`},
  {key: 'SM', query: `(min-width: ${sm}px) and (max-width: ${md - 1}px)`},
  {key: 'MD', query: `(min-width: ${md}px) and (max-width: ${lg - 1}px)`},
  {key: 'LG', query: `(min-width: ${lg}px)`},
];

let subscribers;
let mq = {};

export function getMedia(): MQ {
  return mq as MQ;
}

function getMq(): MQ {
  const _mq = {} as MQ;

  queries.map((item) => {
    _mq[item.key] = window.matchMedia(item.query).matches;
  });

  _mq['MS_AND_DOWN'] = _mq.MS || _mq.XS;
  _mq['SM_AND_DOWN'] = _mq.SM || _mq.MS_AND_DOWN;
  _mq['MD_AND_DOWN'] = _mq.MD || _mq.SM_AND_DOWN;

  _mq['MD_AND_UP'] = _mq.MD || _mq.LG;
  _mq['SM_AND_UP'] = _mq.SM || _mq.MD_AND_UP;
  _mq['MS_AND_UP'] = _mq.MS || _mq.SM_AND_UP;

  return _mq;
}

function publish(q): void {
  mq = getMq();

  if (q.matches) {
    subscribers.map((cb) => {
      if (cb) cb(mq);
    });
  }
}

function initMq(): void {
  subscribers = [];
  mq = getMq();
  queries.map((item) => {
    const media = window.matchMedia(item.query);

    if (media.addEventListener) {
      media.addEventListener('change', publish);
    } else {
      // this is deprecated but some mobile browsers still use it and don't support addEventListener
      media.addListener(publish);
    }
  });
}

export function watchMedia(cb, autoCall = false): {remove: () => void} {
  if (subscribers === undefined) initMq();

  const index = subscribers.push(cb) - 1;
  autoCall && cb(getMedia());
  return {
    remove: (): void => {
      delete subscribers[index];
    },
  };
}
