import {ApiErrorHandler, ApiErrorHandlerType} from './ApiErrorHandler';

export class AbortedRequest extends Error {}

/**
 * Validates the response can be handled by this error class and builds the error object
 */
export function buildApiError<FieldErrors = Record<string, string>>(
  ErrorHandler: ApiErrorHandlerType<FieldErrors>,
  reqErrorContent: unknown
): ApiErrorHandler<FieldErrors> | undefined {
  const fieldErrors = ApiErrorHandler.validateApiErrors(reqErrorContent);
  if (fieldErrors) {
    return new ErrorHandler(fieldErrors);
  }
}
