import {EducationPhase, UserIdentifier} from 'src/types/enums';

export const getUserIdentifier = (
  userType: string,
  educationPhase: EducationPhase
): UserIdentifier =>
  [userType, educationPhase]
    // Some user types have no education phase
    .filter(Boolean)
    .join(':')
    .toLowerCase() as UserIdentifier;
