import {ready} from 'shared/utils';

export const getElementOnReady = (domElementId): Promise<HTMLElement> =>
  new Promise((resolve, reject): void => {
    ready((): void => {
      const element = document.getElementById(domElementId);
      if (element) {
        resolve(element);
      } else {
        reject(`Missing element id [${domElementId}]`);
      }
    });
  });
