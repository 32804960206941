import qs from 'qs';
import {FormOption} from 'src/types/form';
import {Address} from 'src/types/user';
import {AddressVerification, createAddressString, getSuggestedAddress} from './utils';
import {pickBy} from 'lodash';
import {AssetResponse, CountryItem, CountryOption, SchoolOption, SchoolResponse} from './types';
import req from 'shared/req';

export interface GetCountryOptionsParameters {
  includeMeta?: boolean;
  includeUs?: boolean;
}

export default class AssetService {
  static async getCountryList(excludeTerritories = true, includeUs = true): Promise<CountryItem[]> {
    let response;

    try {
      response = await req({
        url: `/v1/lists/countries?${qs.stringify({excludeTerritories})}`,
      });
    } catch (error) {
      return [];
    }

    let countries = response as CountryItem[];
    if (!includeUs) {
      countries = countries.filter((country) => country.code !== 'US');
    }
    return countries;
  }

  static async getCountryOptions(
    params: GetCountryOptionsParameters = {}
  ): Promise<CountryOption[] | FormOption[]> {
    const list = await AssetService.getCountryList(true, params.includeUs);
    return list.map((country) =>
      params.includeMeta
        ? {
            _id: country.code,
            value: country.code,
            text: country.name,
            hsEligible: country.hsEligible,
            inEU: country.inEU,
          }
        : AssetService.formatOptions(country)
    );
  }

  static async getSubdivisions(country = 'US'): Promise<AssetResponse[]> {
    if (!country) {
      console.error('country should not be empty', country);
      return [];
    }

    let response;

    try {
      response = await req({
        url: `/address_verification/subdivisions?${qs.stringify({country})}`,
      });
    } catch (error) {
      return [];
    }

    const subdivisions = response.subdivisions;

    return Object.keys(subdivisions).map((key) => ({name: subdivisions[key], code: key}));
  }

  static async getCities(country = 'US', subdivision?: string): Promise<FormOption[]> {
    let response;

    try {
      response = await req({
        url: `/v1/cities?${qs.stringify(pickBy({country, subdivision}))}`,
      });
    } catch (error) {
      console.log('Failed to load cities', error && error.toString());
      return [];
    }

    const cities = response.cities as string[];
    return cities.map((city) => ({_id: city, value: city, text: city}));
  }

  static async getSchools(
    country = 'US',
    city: string,
    subdivision?: string
  ): Promise<SchoolOption[]> {
    let response;
    const query = qs.stringify(
      pickBy({
        city,
        country,
        subdivision: country === 'US' && subdivision,
      })
    );

    try {
      response = await req({
        url: `/v1/schools?${query}`,
      });
    } catch (error) {
      return [];
    }

    const schools = response as SchoolResponse[];
    return schools.map(AssetService.formatSchoolOption);
  }

  static async getSubdivisionOptions(country = 'US'): Promise<FormOption[]> {
    const subdivisions = await AssetService.getSubdivisions(country);
    return subdivisions
      .map(AssetService.formatOptions)
      .sort((sub1: FormOption, sub2: FormOption) => sub1.text.localeCompare(sub2.text));
  }

  static async getEligibleCountryOptions(): Promise<FormOption[]> {
    const countries = await AssetService.getCountryList();
    return countries.filter((c) => c.hsEligible).map(AssetService.formatOptions);
  }

  static async verifyAddress(address: Address): Promise<Address | false> {
    const addressString = createAddressString(address);
    const url = `/students/verify_address?${qs.stringify({street: addressString})}`;
    try {
      const response = await req<AddressVerification[]>({url});
      return getSuggestedAddress(addressString, address, response);
    } catch (error) {
      console.log(`Error verifying address ${error}`);
    }

    return false;
  }

  private static formatOptions(response: AssetResponse): FormOption {
    return {_id: response.code, value: response.code, text: response.name};
  }

  private static formatSchoolOption(school: SchoolResponse): SchoolOption {
    return {
      id: school.id,
      name: school.fields.schoolName,
      _id: school.id,
      value: school.id,
      text: school.fields.schoolName,
      meta: {
        address: school.fields.address,
        city: school.fields.city,
        country: school.fields.countryCode,
        countryFullName: school.fields.country,
        postalCode: school.fields.postalCode,
        subdivision: school.fields.subdivision,
        subdivisionCode: school.fields.subdivisionCode,
      },
    };
  }
}
